import { render, staticRenderFns } from "./VehicleRefStandardized.vue?vue&type=template&id=136d13d8&scoped=true"
import script from "./VehicleRefStandardized.vue?vue&type=script&lang=js"
export * from "./VehicleRefStandardized.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136d13d8",
  null
  
)

export default component.exports