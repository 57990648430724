var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elite-tabs-wrapper-content"},[(_vm.viewHeader)?_c('estimate-ref-standardized',{attrs:{"show-buttons":_vm.showButtons,"canCancel":_vm.canCancelEstimate(_vm.estimate),"canEdit":_vm.canEditEstimate(_vm.estimate),"estimate":_vm.estimate},on:{"cancel":function($event){_vm.showEstimateCancelModel = true},"edit":_vm.editEstimate}}):_vm._e(),_c('div',{staticClass:"global-body"},[_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CODE")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate}})],1)]),_c('dl',{staticClass:"width_1-2 status"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.STATUS")))]),_c('dd',[_c('estimate-status-badge',{attrs:{"status":_vm.estimate.status,"estimate":_vm.estimate}})],1)]),_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.EXCERPT")))]),_c('dd',{staticClass:"col-sm-8",domProps:{"innerHTML":_vm._s(_vm.estimate.excerpt)}})]),(_vm.estimate.times_status)?_c('dl',{staticClass:"width_1-2 status"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.TIMES_STATUS")))]),_c('dd',[_c('estimate-status-badge',{attrs:{"status":_vm.estimate.times_status,"estimate":_vm.estimate}},[_vm._v(" "+_vm._s(_vm.$tc(`ESTIMATES.ESTIMATE_TYPES_STATUS_${_vm.estimate.times_status}`) ? _vm.$t(`ESTIMATES.ESTIMATE_TYPES_STATUS_${_vm.estimate.times_status}`) : "-")+" ")])],1)]):_vm._e(),(_vm.estimate.components_status)?_c('dl',{staticClass:"width_1-2 status"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.COMPONENTS_STATUS")))]),_c('dd',[_c('estimate-status-badge',{attrs:{"status":_vm.estimate.components_status,"estimate":_vm.estimate}},[_vm._v(" "+_vm._s(_vm.$tc( `ESTIMATES.ESTIMATE_TYPES_STATUS_${_vm.estimate.components_status}` ) ? _vm.$t( `ESTIMATES.ESTIMATE_TYPES_STATUS_${_vm.estimate.components_status}` ) : "-")+" ")])],1)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        _vm.$idExist(_vm.estimate.customer?.id) &&
        !!_vm.estimate.customer?.customer_type
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CUSTOMER")))]),_c('dd',[_c('users',{attrs:{"users":[_vm.estimate.customer]}})],1)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        _vm.$idExist(_vm.estimate.approver?.id) &&
        !!_vm.estimate.approver?.email
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.APPROVER")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.approver}})],1)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_VEHICLES) &&
        !!_vm.estimate.vehicle
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.VEHICLE")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.vehicle}})],1)]):_vm._e(),_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("VEHICLES.ODOMETER")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.estimate.odometer ?? _vm.estimate.vehicle?.odometer)+" "+_vm._s(_vm.estimate.vehicle?.odometer_unit)+" ")])]),_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.LOCATIONS")))]),_c('dd',[_c('locations',{attrs:{"locations":_vm.estimate.allowedLocations}})],1)]),(!!_vm.estimate.created_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.CREATED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.estimate.created_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.estimate.creator && _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS)
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.CREATOR")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.creator}})],1)]):_vm._e(),(!!_vm.estimate.validated_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.VALIDATED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.estimate.validated_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.estimate.validatedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.VALIDATED_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.validatedBy}})],1)]):_vm._e(),(!!_vm.estimate.started_components_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.STARTED_COMPONENTS_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.estimate.started_components_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.estimate.startedComponentsBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.STARTED_COMPONENTS_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.startedComponentsBy}})],1)]):_vm._e(),(!!_vm.estimate.ended_components_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.ENDED_COMPONENTS_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.estimate.ended_components_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.estimate.endedComponentsBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.ENDED_COMPONENTS_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.endedComponentsBy}})],1)]):_vm._e(),(!!_vm.estimate.started_times_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.STARTED_TIMES_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.estimate.started_times_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.estimate.startedTimesBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.STARTED_TIMES_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.startedTimesBy}})],1)]):_vm._e(),(!!_vm.estimate.ended_times_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.ENDED_TIMES_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.estimate.ended_times_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.estimate.endedTimesBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.ENDED_TIMES_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.endedTimesBy}})],1)]):_vm._e(),(!!_vm.estimate.started_approve_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.STARTED_APPROVE_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.estimate.started_approve_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.estimate.startedApproveBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.STARTED_APPROVE_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.startedApproveBy}})],1)]):_vm._e(),(!!_vm.estimate.ended_approve_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.ENDED_APPROVE_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.estimate.ended_approve_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.estimate.endedApproveBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.ENDED_APPROVE_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.endedApproveBy}})],1)]):_vm._e(),(!!_vm.estimate.reviewed_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.REVIEWED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.estimate.reviewed_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.estimate.reviewedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("ESTIMATES.REVIEWED_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.reviewedBy}})],1)]):_vm._e(),(!!_vm.estimate.canceled_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("ESTIMATES.CANCELED_AT"))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.estimate.canceled_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.estimate.canceledBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("ESTIMATES.CANCELED_BY"))+" ")]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.canceledBy}})],1)]):_vm._e(),(!!_vm.estimate.completed_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("ESTIMATES.COMPLETED_AT"))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.estimate.completed_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.estimate.completedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("ESTIMATES.COMPLETED_BY"))+" ")]),_c('dd',[_c('object-link',{attrs:{"object":_vm.estimate.completedBy}})],1)]):_vm._e(),(
        _vm.estimate.organization &&
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ORGANIZATION")))]),_c('dd',[_c('organization',{attrs:{"organization":_vm.estimate.organization}})],1)]):_vm._e()]),_c('inspection-confirmation-modal',{attrs:{"confirm-action":_vm.cancelEstimate,"confirm-button-text":_vm.$t('COMMON.YES_CANCEL'),"loading":_vm.loading,"message":_vm.$t('ESTIMATES.CANCEL_THIS_ESTIMATE'),"open":_vm.showEstimateCancelModel,"modal-classes":"modal-secondary cancel"},on:{"update:open":function($event){_vm.showEstimateCancelModel=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }