<template>
  <div class="global-header">
    <div class="global-header-image">
      <img v-if="image" :src="image" class="object-cover" alt="logo" />
      <img v-else class="default" src="/img/add-image.svg" />
    </div>
    <div class="global-header-text">
      <h3>{{ title }}</h3>
      <slot name="second-title">
        <h4 class="text-muted" v-if="secondTitle">{{ secondTitle }}</h4>
      </slot>
      <div v-if="showBtn" class="buttons">
        <slot name="buttons">
          <base-button
            class="btn"
            size="sm"
            type="button"
            @click.prevent="btnAction"
          >
            <span>{{ btnLabel ?? $t("COMMON.UPDATE") }}</span>
            <i class="fal fa-edit"></i>
          </base-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-object-ref-widget",

  props: {
    image: {
      type: String | null | undefined,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    secondTitle: {
      type: String,
      required: null,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
    btnLabel: {
      type: String | null | undefined,
      default: null,
    },
    btnAction: {
      type: Function,
      default: () => {},
    },
  },

  computed: {},
};
</script>
