<template>
  <view-object-ref-widget
    :image="vehicle.gallery[0] ?? null"
    :second-title="vehicle.vin"
    :title="$objectDenomination(vehicle)"
    class="border-0"
  >
    <template #second-title>
      <ul>
        <li>{{ vehicle.odometer }} {{ vehicle.odometer_unit }}</li>
        <li class="line">|</li>
        <li><copy-element :element="vehicle.vin" /></li>
      </ul>
    </template>
    <template #buttons>
      <button
        class="edit"
        @click="update"
        v-if="$currentUserCan($permissions.PERM_EDIT_VEHICLES)"
      >
        <span> {{ $t("COMMON.UPDATE") }} </span>
        <i class="far fa-edit"></i>
      </button>
    </template>
  </view-object-ref-widget>
</template>

<script>
import CopyElement from "./CopyElement.vue";
import ViewObjectRefWidget from "./ViewObjectRefWidget.vue";
import { QUERY_ACTIONS_EDIT } from "@/constants/common";

export default {
  name: "vehicle-ref-standardized",

  components: { CopyElement, ViewObjectRefWidget },

  props: ["vehicle"],

  data() {
    return {
      QUERY_ACTIONS_EDIT,
    };
  },

  methods: {
    update() {
      this.$emit("onEditVehicle");
      if (this.$route.name != "List Vehicles") {
        this.$router.push({
          name: "List Vehicles",
          query: { id: this.vehicle.id, action: QUERY_ACTIONS_EDIT },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
