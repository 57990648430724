<template>
  <view-object-ref-widget
    :image="estimate.vehicle.gallery[0] ?? null"
    :title="estimate.code"
  >
    <template #buttons>
      <button v-if="canCancel" @click="$emit('cancel')" class="delete">
        <span> {{ $t("COMMON.CANCEL") }} </span>
        <i class="far fa-trash-alt"></i>
      </button>
      <button v-if="canEdit" @click="$emit('edit')" class="edit">
        <span> {{ $t("COMMON.UPDATE") }} </span>
        <i class="far fa-edit"></i>
      </button>
      <button v-if="canView" @click="view" class="view">
        <span> {{ $t("ESTIMATES.SHOW_ESTIMATE") }} </span>
        <!-- <i class="fal fa-share"></i> -->
      </button>
    </template>
  </view-object-ref-widget>
</template>

<script>
import ViewObjectRefWidget from "./ViewObjectRefWidget.vue";
import { QUERY_ACTIONS_EDIT } from "@/constants/common";

export default {
  name: "estimate-ref-standardized",

  components: { ViewObjectRefWidget },

  props: {
    estimate: {
      type: Object,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      QUERY_ACTIONS_EDIT,
    };
  },

  computed: {},

  methods: {
    view() {
      this.$emit("onView");
      if (this.$route.name != "List Estimates") {
        this.$router.push({
          name: "List Estimates",
          query: { id: this.estimate.id, action: QUERY_ACTIONS_EDIT },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
